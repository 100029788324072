import React from 'react'
import styled from 'styled-components'

import './spinner.css'

const BackDrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh + 6rem);
`

const index = () => {
  return (
    <BackDrop>
      <LoadingWrapper>
        <div class='lds-ripple'>
          <div></div>
          <div></div>
        </div>
      </LoadingWrapper>
    </BackDrop>
  )
}

export default index
