import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from '../Components/Layout'
import AppRoute from '../Components/AppRoute'
import AppLinks from './AppRoutes'

const SearchRecipes = React.lazy(() => import('../Pages/SearchRecipes'))
const Login = React.lazy(() => import('../Pages/Auth/Login'))
const Signup = React.lazy(() => import('../Pages/Auth/SignUp'))

const index = () => {
  return (
    <Switch>
      <AppRoute
        exact
        path={AppLinks.SEARCH_RECIPES}
        component={SearchRecipes}
        layout={AuthLayout}
      />
      <Route exact path={AppLinks.ROOT} component={Login} />
      <Route exact path={AppLinks.AUTH_ROUTES.SIGN_UP} component={Signup} />

      <Redirect from={AppLinks.AUTH_ROUTES.LOGIN} to={AppLinks.ROOT} />
    </Switch>
  )
}

export default index
