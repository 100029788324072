import React from 'react'
import styled from 'styled-components'

import NavBar from '../NavBar'
import SideBar from '../SideBar'
import ContentWrapper from '../ContentWrapper'

const LayoutWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'header header '
    'sidebar content';
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
  height: 100vh;
`

const index = ({ children }) => {
  return (
    <LayoutWrapper>
      <NavBar />
      <SideBar />
      <ContentWrapper>{children}</ContentWrapper>
    </LayoutWrapper>
  )
}

export default index
