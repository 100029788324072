import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { StylesProvider } from '@material-ui/styles'
import { ApolloProvider } from '@apollo/react-hooks'

import theme from './Theme'
import Routes from './Routes'
import Spinner from './Components/Spinner'
import { GlobalStyles } from './Styles'
import client from './Graphql'

const history = createBrowserHistory()

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Router history={history}>
          <React.Suspense fallback={<Spinner />}>
            <GlobalStyles />
            <ApolloProvider client={client}>
              <Routes />
            </ApolloProvider>
          </React.Suspense>
        </Router>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default App
