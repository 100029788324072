export default {
  // Primary Colors and its varients

  primaryColor: '#99cc66', // Green Color
  primaryColorDark: '#85c249',

  // Secondary Colors and its varients

  secondaryColor: '#E4572E',
  secondaryColorDark: '#d0441b',

  primaryTextColor: '#4c4d4c', // ash black color
  secondaryTextColor: '#555F6B',
  primaryButtonColor: '#d81159',

  // Error Text Color
  errorColor: 'red',

  // New color Pallete

  backgroundColor: '#FFFCFF',

  menuFontSize: '1.4rem',
  mainBackgroundColor: '#FFFCFF',
  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
}
