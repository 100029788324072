import React from 'react'
import styled from 'styled-components'

import Icon from '../Icon'
import { withRouter } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'

const AvatarContainer = styled.div`
  position: relative;
`

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  cursor: pointer;

  &:hover {
    background-color: #696b69;
  }
`

const AvatarImg = styled.img`
  width: 5rem;
  height: 5rem;
  transform: translateX(-2px);
  background-color: rgba(255, 255, 255);
  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.primaryColor};
`

const AvatarName = styled.span`
  font-size: ${(props) => props.theme.menuFontSize};
  font-weight: 400;
  margin-left: 1rem;
`

const AvatarIcon = styled.div`
  transform: translateY(2px);
  i {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
`

const AvatarDropDownMenu = styled.ul`
  position: absolute;
  width: 100%;
  top: 6rem;
  left: 0;
  right: 0;
  background-color: #4c4d4c;
  display: ${(props) => (props.selected ? '' : 'none')};
`

const DropDownItems = styled.li`
  display: flex;
  padding: 2rem 2rem;
  font-size: ${(props) => props.theme.menuFontSize};
  font-weight: 400;
  cursor: pointer;

  & i {
    padding-right: 1rem;
    font-size: 2rem;
  }

  &:hover {
    background-color: #696b69;
  }
`

const Index = ({ history }) => {
  const [selected, setSelected] = React.useState(false)

  const onLogOutClickHandler = () => {
    localStorage.removeItem('token')
    history.push({
      pathname: '/login',
      state: {
        message: 'You are logged out!'
      }
    })
  }

  return (
    <ClickAwayListener onClickAway={() => setSelected(false)}>
      <AvatarContainer>
        <AvatarWrapper onClick={() => setSelected((selected) => !selected)}>
          <AvatarImg src='https://robohash.org/milin.png' />
          <AvatarName>Milin Paul</AvatarName>
          <AvatarIcon>
            <Icon iconName='expand_more' />
          </AvatarIcon>
        </AvatarWrapper>
        <AvatarDropDownMenu selected={selected}>
          <DropDownItems onClick={onLogOutClickHandler}>
            <Icon iconName='power_settings_new' />
            Log Out
          </DropDownItems>
        </AvatarDropDownMenu>
      </AvatarContainer>
    </ClickAwayListener>
  )
}

export default withRouter(Index)
