import React from 'react'
import styled from 'styled-components'

import Avatar from '../Avatar'

const NavBar = styled.header`
  grid-area: header;
  position: fixed;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4c4d4c;
  color: #fff;
  box-shadow: ${(props) => props.theme.boxShadow};
  z-index: 9999;
`

const NavBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.h2`
  font-family: 'Pacifico' !important;
  color: #fff;
  letter-spacing: 4px;
  font-size: 2.6rem;
  padding: 0 3rem;
`

const NavLinks = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7rem;
`

const index = () => {
  return (
    <NavBar>
      <NavBarContainer>
        <Logo>Meal Planner</Logo>
        <NavLinks>
          <Avatar />
        </NavLinks>
      </NavBarContainer>
    </NavBar>
  )
}

export default index
