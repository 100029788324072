const ROOT = '/'

const AUTH_ROUTES = {
  LOGIN: '/login',
  SIGN_UP: '/signup',
}

const DASHBOARD = '/dashboard'
const SEARCH_RECIPES = '/search'

export default {
  ROOT,
  AUTH_ROUTES,
  DASHBOARD,
  SEARCH_RECIPES,
}
