import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,700&display=swap');    
@import url('https://fonts.googleapis.com/css?family=Pacifico&display=swap');
  
  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
    line-height: 1.6;
  }
  body {
    font-family: 'Raleway', sans-serif;
    position: relative;
    background-color: #f5f4fa;
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: #ccc;
  }
`
