import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const index = ({ component: Component, layout: Layout, ...rest }) => {
  const token = localStorage.getItem('token')
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  )
}

export default index
