import ApolloClient from 'apollo-boost'

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL
  // request: (operation) => {
  //   const token = localStorage.getItem('token')
  //   operation.setContext({
  //     headers: {
  //       authorization: token ? `Bearer ${token}` : ''
  //     }
  //   })
  // }
})

export default client
