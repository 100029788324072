import React from 'react'
import styled from 'styled-components'

import Icon from '../Icon'

const SidebarWrapper = styled.div`
  grid-area: sidebar;
  background-color: #4c4d4c;
  position: fixed;
  top: 6rem;
  left: 0;
  height: 100%;
  width: 13%;
  z-index: 9999;
`

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0;
`

const SidebarItems = styled.div`
  display: flex;
  align-items: center;
  padding: 1.3rem 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 2%;
  margin-top: auto;
  color: ${(props) => (props.active ? props.theme.primaryColor : '#fff')};
  border-left: ${(props) => (props.active ? `5px solid #99cc66` : '')};

  &:hover {
    color: #fff;
    border-radius: 2%;
    background-color: rgba(153, 204, 102, 0.09);
    border-left: 5px solid ${(props) => props.theme.primaryColor};
  }

  i {
    margin-right: 1rem;
    font-size: 2rem;
  }

  span {
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: ${(props) => props.theme.menuFontSize};
    font-weight: 300;
  }
`

const index = () => {
  return (
    <SidebarWrapper>
      <SidebarContainer>
        {/* <SidebarItems active>
          <Icon iconName='dashboard' />
          <span>Dashboard</span>
        </SidebarItems> */}
        <SidebarItems active>
          <Icon iconName='search' />
          <span>Search</span>
        </SidebarItems>
        <SidebarItems>
          <Icon iconName='add' />
          <span>Add Recipes</span>
        </SidebarItems>
        <SidebarItems>
          <Icon iconName='settings' />
          <span>Settings</span>
        </SidebarItems>
      </SidebarContainer>
    </SidebarWrapper>
  )
}

export default index
